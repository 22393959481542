// © copyright by VDARE Foundation, USA
//
// This file has been (co)-authored by Serhii Khoma in 2019-2020, Oleksandr Papevis in 2020

// https://github.com/RHNorskov/EasyEmbed/blob/master/jquery.easyembed.js
//
/* eslint-disable */

;(function ($, window, document) {
  // eslint-disable-next-line no-param-reassign
  $.fn.easyEmbed = function (options) {
    const $that = this

    // detect if device requires user interaction for playback
    const mobile = /iPad|iPhone|iPod/.test(navigator.userAgent)

    // translate shorthand
    const shorthand =
      $that.data("easy-embed") && $that.data("easy-embed").split(":")

    const settings = $.extend(
      {
        // general settings
        id: $that.data("id") || shorthand[1] || "ScMzIvxBSi4",
        provider: $that.data("provider") || shorthand[0] || "youtube",
        width: $that.data("width") || 16,
        height: $that.data("height") || 9,

        // youtube settings
        controls: $that.data("controls") || false,
        showinfo: $that.data("showinfo") || false,

        // vimeo settings
        color: $that.data("color") || "00adef",
        title: $that.data("title") || false,
        byline: $that.data("byline") || false,
        portrait: $that.data("portrait") || false,
      },
      options
    )

    const getThumbnail = function (callback) {
      switch (settings.provider.toLowerCase()) {
        case "youtube":
          var base = `//img.youtube.com/vi/${settings.id}/`
          var sizes = ["maxresdefault", "hqdefault"]
          ;(function getImage() {
            const size = sizes[0]

            if (!size) {
              return
            }

            const url = `${base + size}.jpg`

            $("<img/>")
              .attr("src", url)
              .on("load", function () {
                if (this.width != 120 && this.height != 90) {
                  callback(url)
                } else {
                  sizes.shift()
                  getImage()
                }
              })
          })()

          break

        case "foxnews":
          $.get(`https://video.foxnews.com/v/${settings.id}/`, function (data) {
            callback(
              $($.parseHTML(data)).filter('meta[property="og:image"]')[0]
                .content
            )
          })

          break

        case "bitchute":
          $.get(
            `https://www.bitchute.com/embed/${settings.id}/`,
            function (data) {
              callback(
                $($.parseHTML(data)).filter('meta[property="og:image"]')[0]
                  .content
              )
            }
          )

          break

        case "vimeo":
          // $.get('//vimeo.com/api/v2/video/' + settings.id + '.json', function (data) {
          //    callback(data[0].thumbnail_large);
          // })

          $.get(
            `https://vimeo.com/api/oembed.json?url=http://vimeo.com/${settings.id}`,
            (data) => {
              callback(data.thumbnail_url)
            }
          )

          break

        case "rumble":
          $.get(`https://rumble.com/embed/${settings.id}/?pub=4`, (data) => {
            callback(data.thumbnail_url)
          })

          break

        case "gab_tv":
          $.get(`https://tv.gab.com/channel/${settings.id}`, (data) => {
            callback(data.thumbnail_url)
          })

          break
      }
    }

    const getSource = function () {
      switch (settings.provider.toLowerCase()) {
        case "youtube":
          return (
            `//youtube.com/embed/${settings.id}?rel=0&autoplay=1` +
            `&controls=${settings.controls + 0}&showinfo=${
              settings.showinfo + 0
            }`
          )

          break

        case "foxnews":
          return `//video.foxnews.com/static/p/video/app/embed/iframe/vod.html?video_id=${settings.id}`

          break

        case "bitchute":
          return `//www.bitchute.com/embed/${settings.id}/`

          break

        case "vimeo":
          return (
            `//player.vimeo.com/video/${settings.id}?autoplay=1` +
            `&color=${settings.color}&title=${
              settings.title + 0
            }&byline=${settings.byline + 0}&portrait=${settings.controls + 0}`
          )

          break

        case "rumble":
          $.get(`https://rumble.com/embed/${settings.id}/?pub=4`, (data) => {
            callback(data.thumbnail_url)
          })

          break

        case "gab_tv":
          $.get(`https://tv.gab.com/channel/${settings.id}`, (data) => {
            callback(data.thumbnail_url)
          })

          break
      }
    }

    const setThumbnail = function (src) {
      $that.css("background", `black url(${src}) 50% 50% / cover no-repeat`)
    }

    const setSize = function () {
      console.log('resize', $that)
      $that.css("height", ($that.width() / settings.width) * settings.height)
    }

    const setIframe = function () {
      $that.html(
        $("<iframe>")
          .attr("src", getSource())
          .attr("width", "100%")
          .attr("height", "100%")
          .attr("frameborder", 0)
          .attr("allowfullscreen", 1)
          .attr("sandbox", "allow-same-origin allow-scripts allow-forms")
      )
      $that.addClass("playing-video")
    }

    setSize()

    $(window).on("resize", function() {
      // console.log('resize')
      setSize()
    })

    if (!mobile) {
      getThumbnail((url) => {
        setThumbnail(url)
      })

      $that
        .find("*")
        .addBack()
        .click(() => {
          setIframe()
        })
    } else {
      setIframe()
    }

    return this
  }
})(jQuery, window, document)
