import * as R from 'ramda'
import waitForLazyloadScript from '@vdareapp/shared/lib/waitForLazyloadScript'

import turbolinksLoadedObservable from '@vdareapp/shared/observables/turbolinksLoadedObservable'

const makeInstanceOnce = R.once(async (options) => {
  const LazyLoad = await waitForLazyloadScript()

  return new LazyLoad(options)
})

turbolinksLoadedObservable.subscribe(async () => {
  const lazyLoad = await makeInstanceOnce({
    elements_selector: '[data-lazy]',
  })

  lazyLoad.update()
})
