;(function ($, window) {
  console.log('window.appRailsEnv', window.appRailsEnv)

  const warnOrError =
    window.appRailsEnv === 'production'
      ? (message) => {
          console.warn(message)
        }
      : (message) => {
          throw new Error(message)
        }

  // eslint-disable-next-line no-param-reassign
  $.fn.expectLength = function (expectedLength) {
    const expectedLength_ = expectedLength || 1

    if (this.length !== expectedLength_) {
      const message = `unable to find ${expectedLength_} element with selector ${this.selector}. ${this.length} element is found.`

      warnOrError(message)
    }

    return this
  }

  // eslint-disable-next-line no-param-reassign
  $.fn.expectLengthAtLeast = function (expectedLength) {
    const expectedLength_ = expectedLength || 1

    if (this.length < expectedLength_) {
      const message = `unable to find at least ${expectedLength_} element with selector ${this.selector}. ${this.length} element is found.`

      warnOrError(message)
    }

    return this
  }
})(jQuery, window)
