function waitForLazyloadScript () {
  return new Promise((resolve, reject) => {
    if (window.LazyLoad) {
      resolve(window.LazyLoad)
    } else {
      const scriptObject = document.querySelector('#lazyload-js')

      if (!scriptObject) {
        reject(new Error('no <script id="lazyload-js"> on page'))
      }

      scriptObject.addEventListener('load', () => {
        resolve(window.LazyLoad)
      })
    }
  })
}

export default waitForLazyloadScript
